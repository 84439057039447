<template>
  <div class="admin-create">
    <div class="admin-navbar align-left">
      <button class="action-button" @click="$router.go(-1)">{{ $lang.app.back }}</button>
    </div>
    <h4 class="create-title">{{ $lang.app.creation }}</h4>
    <div class="admin-form-wrapper">
      <div class="admin-form">
        <Form @submit="submit">
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.heading }} ({{ $lang.app.ru }}) <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="titleRu" type="text"
                     v-model="lawItem.titleRu"/>
            </div>
            <ErrorMessage name="titleRu" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.heading }} ({{ $lang.app.kg }}) <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="titleKg" type="text"
                     v-model="lawItem.titleKg"/>
            </div>
            <ErrorMessage name="titleKg" class="recovery-label-error" />
          </div>
          <div class="file-input reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.attach_file }}</label>
            <Field type="file" name="filename" accept="application/pdf"
                   @change="event => lawItem.fileDemo = event.target.files[0]"/>
            <ErrorMessage name="filename" class="recovery-label-error"/>
          </div>
          <div class="reg-step-btn">
            <button type="submit">{{ $lang.app.create }}</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import fileUploadMixin from "../../../mixins/fileUploadMixin";
export default {
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  mixins: [fileUploadMixin],
  data() {
    return {
      lawItem: {
        titleRu: '',
        titleKg: '',
        filename: null,
        fileDemo: null,
      }
    }
  },
  methods: {
    async submit(values, actions) {
      if(!this.lawItem.filename && this.lawItem.fileDemo) {
        try {
          this.lawItem.filename = await this.uploadFile(this.lawItem.fileDemo)
        } catch (error) {
          if(error.response.status === 400 || error.response.status === 413) {
            actions.setErrors({
              filename: error.response.data.errors.file,
            });
          } else {
            this.$snotify.error(error)
          }
          return
        }
      }
      this.$axios.post(`/legal-act/create`, this.lawItem).then(() => {
        this.$snotify.success(this.$lang.app.resource_created_successfully);
        this.$router.push('/admin/law')
      }).catch(error => {
        if(error.response.status === 400) {
          actions.setErrors(error.response.data);
        }
      })
    },
  },
}
</script>
<style>
@import '../../../assets/styles/admin/create.css';
@import '../../../assets/styles/admin/navbar.css';
@import '../../../assets/styles/admin/form.css';
@import '../../../assets/styles/form.css';
</style>